import {
    SHOW_ARTICLE_LIKEFOR_UPLOAD_DIALOG,
    HIDE_ARTICLE_LIKEFOR_UPLOAD_DIALOG,
    SHOW_ARTICLE_LIKEFORUPGRADE_UPLOAD_DIALOG,
    HIDE_ARTICLE_LIKEFORUPGRADEUPLOAD_DIALOG,
    SHOW_ARTICLE_NEXTGENERATION_UPLOAD_DIALOG,
    HIDE_ARTICLE_NEXTGENERATION_UPLOAD_DIALOG,
    ARTICLELIKEFOR_GET_TAGS_FOR_MAPPING_LIST,
    ARTICLELIKEFOR_GET_RECOMMENDATION_TAGS_FOR_MAPPING_LIST,
    ARTICLELIKEFOR_GET_GROUPING_BY_SKUS,
    ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_LIST,
    ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_LIST_FILTER,
    ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_LIST_CLEAR_FILTER,
    ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_SCHEDULE,
    ARTICLELIKEFOR_GET_LIKEFORSIMILAR_COUNTRY_CARRIER,
    ARTICLELIKEFOR_GET_LIKEFOR_NEXTGENERATION_UPDATES_LIST,
    ARTICLELIKEFOR_GET_LIKEFOR_NEXTGENERATION_UPDATES_LIST_CLEAR_FILTER,
    ARTICLELIKEFOR_GET_LIKEFOR_NEXTGENERATION_UPDATES_LIST_FILTER,
} from "../actions/types";

const INITIAL_STATE = {
    articleLikeForUploadDialog: {
        entity: null,
        open: false,
        prepopulateField: 'updateAll',
    },
    articleLikeForUpgradeUploadDialog: {
        entity: null,
        open: false,
        prepopulateField: 'updateAll',
    },
    articleNextGenerationUploadDialog: {
        entity: null,
        open: false,
        prepopulateField: 'updateAll',
    },
    tagsForMapping: [],
    recommendationTagsForMapping: [],
    groupingBySku: [],
    likeForSimilarRecommendationUpdatesList: {},
    articleLikeForUpdateSchedule: null,
    articleLikeForCountryCarrier: {},
    likeForSimilarRecommendationUpdatesListFilters: {
        email: '',
        name: '',
        businessEntityCode: '',
        locked: [],
        active: [],
        description: '',
        countryCode: [],
        authorities: [],
        createdAt: {
            from: null,
            to: null
        }
    },
    likeForNextGenerationUpdatesList: {},
    likeForNextGenerationUpdatesListFilters: {
        description: '',
    },
}

const articleLikeForReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_ARTICLE_LIKEFOR_UPLOAD_DIALOG:
            return {
                ...state,
                articleLikeForUploadDialog: {
                    entity: action.payload.entity,
                    open: true,
                    prepopulateField: action.payload.prepopulateField,
                }
        }
        case SHOW_ARTICLE_LIKEFORUPGRADE_UPLOAD_DIALOG:
            return {
                ...state,
                articleLikeForUpgradeUploadDialog: {
                    entity: action.payload.entity,
                    open: true,
                    prepopulateField: action.payload.prepopulateField,
                }
        }
        case SHOW_ARTICLE_NEXTGENERATION_UPLOAD_DIALOG:
            return {
                ...state,
                articleNextGenerationUploadDialog: {
                    entity: action.payload.entity,
                    open: true,
                    prepopulateField: action.payload.prepopulateField,
                }
        }
        case HIDE_ARTICLE_LIKEFOR_UPLOAD_DIALOG:
            return {
                ...state,
                articleLikeForUploadDialog: {
                    entity: null,
                    open: false,
                }
            }
        case HIDE_ARTICLE_LIKEFORUPGRADEUPLOAD_DIALOG:
            return {
                ...state,
                articleLikeForUpgradeUploadDialog: {
                    entity: null,
                    open: false,
                }
            }
        case HIDE_ARTICLE_NEXTGENERATION_UPLOAD_DIALOG:
            return {
                ...state,
                articleNextGenerationUploadDialog: {
                    entity: null,
                    open: false,
                }
            }
        case ARTICLELIKEFOR_GET_TAGS_FOR_MAPPING_LIST:
            return {
                ...state,
                tagsForMapping: action.payload.data,
            }
        case ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_SCHEDULE:
            return {
                ...state,
                articleLikeForUpdateSchedule: action.payload.data,
            }
        case ARTICLELIKEFOR_GET_RECOMMENDATION_TAGS_FOR_MAPPING_LIST:
            return {
                ...state,
                recommendationTagsForMapping: action.payload.data,
            }
        case ARTICLELIKEFOR_GET_GROUPING_BY_SKUS:
            return {
                ...state,
                groupingBySku: action.payload.data,
            }
        case ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_LIST:
            return {
                ...state,
                likeForSimilarRecommendationUpdatesList: action.payload.data
            }
        case ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_LIST_FILTER:
            return {
                ...state,
                likeForSimilarRecommendationUpdatesListFilters: {
                    ...state.likeForSimilarRecommendationUpdatesListFilters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_LIST_CLEAR_FILTER:
            return {
                ...state,
                likeForSimilarRecommendationUpdatesListFilters: {
                    email: '',
                    name: '',
                    businessEntityCode: '',
                    locked: [],
                    active: [],
                    description: '',
                    countryCode: [],
                    authorities: [],
                    createdAt: {
                        from: null,
                        to: null
                    }
                }
            }
        case ARTICLELIKEFOR_GET_LIKEFORSIMILAR_COUNTRY_CARRIER:
            return {
                ...state,
                articleLikeForCountryCarrier: action.payload.data,
            }
        case ARTICLELIKEFOR_GET_LIKEFOR_NEXTGENERATION_UPDATES_LIST:
            return {
                ...state,
                likeForNextGenerationUpdatesList: action.payload.data
            }
        case ARTICLELIKEFOR_GET_LIKEFOR_NEXTGENERATION_UPDATES_LIST_FILTER:
            return {
                ...state,
                likeForNextGenerationUpdatesListFilters: {
                    ...state.likeForNextGenerationUpdatesListFilters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case ARTICLELIKEFOR_GET_LIKEFOR_NEXTGENERATION_UPDATES_LIST_CLEAR_FILTER:
            return {
                ...state,
                likeForNextGenerationUpdatesListFilters: {
                    description: ''
                }
            }
        default:
            return state;
    }
}

export default articleLikeForReducer;