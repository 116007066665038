import React from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Divider,
  Table,
  TableBody,
  TableRow,
  withStyles,
  TableContainer,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MuiTableCell from "@material-ui/core/TableCell";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import { useState, useEffect } from "react";
import { convertToArticleGroupingMappingObject } from "../../../utils/CsvObjectModifier/ArticleLikeForGrouping/ArticleGroupingMapper";
import _ from "lodash";
import "../../../styles/custom-autocomplete-dropdown.css";
import { LIKE_FOR_UPGRADE_INPUT_FIELDS } from "../../../utils/CsvObjectModifier/CsvObjectFormat";
import makeStyles from "@material-ui/core/styles/makeStyles";

const ArticleNextGenerationUploadDialog = ({
  entity,
  open,
  prepopulateField,
  onReject,
  onAccept,
  tagsForMapping,
  groupingBySku,
  onPromptError
}) => {
  useEffect(() => {
    setArticleDescription(_.fromPairs(_.map(groupingBySku, item => [item.sku, item.description])))
    populateInput(groupingBySku);
  }, [groupingBySku, open]);

  const { t } = useTranslation();
  const [disabled, setDisabled] = React.useState(false);
  const [inputs, setInputs] = useState({});
  const [likeForLikeInputError, setLikeForLikeInputError] = React.useState({});
  const [articleDescription, setArticleDescription] = React.useState({});

  const customStyles = makeStyles({
    sticky: {
      position: "sticky",
      left: 0,
      background: "white",
      zIndex: 2
    },
    headers: {
      position: "sticky",
      top: 0,
      background: "white",
      zIndex: 3
    },
    autoCompletePaper: { minWidth: "100%" },
  });
  const customStyleClass = customStyles();

  const isFormValid = () => {
    return Object.keys(likeForLikeInputError).length === 0;
  };

  const populateInput = (existingGroupingValue) => {
    var existingInputObject = {};
    var availableGroupingOptions = [
      "nextgeneration",
    ];

    var tagsForMappingToLikeForLike = _.fromPairs(_.map(groupingBySku, item => [item.sku, item.tagForMapping]));
    entity?.map((uploadedGrouping, index) => {
      var existingGrouping = groupingBySku.filter(
        (data) => data["sku"] === uploadedGrouping["sku"]
      )[0];

      if (existingGrouping) {
        existingInputObject = processInputData(
          uploadedGrouping,
          `${"likeForLike1"}`,
          tagsForMappingToLikeForLike[existingGrouping.sku],
          existingInputObject
        );

        availableGroupingOptions.map((option, index) => {

            var carrierArray =
              existingGrouping?.[option]?.[
                uploadedGrouping["countryCode"].slice(0, 2)
              ]?.[uploadedGrouping["carrierCode"]];

            existingInputObject = processInputData(
              uploadedGrouping,
              `${option}`,
              determineValue(uploadedGrouping, carrierArray != null ? carrierArray[0] : null),
              existingInputObject
            );

            existingInputObject = processInputData(
              uploadedGrouping,
              `${option}${'ExistingValue'}`,
              carrierArray != null ? carrierArray[0] : null,
              existingInputObject
            );
        });
      }
    });
    setInputs(existingInputObject);
  };

  const determineValue = (uploadedGrouping, existingValue) => {
    if(prepopulateField == 'updateAll') {
      if(uploadedGrouping["nextgeneration"]){
        return uploadedGrouping["nextgeneration"];
      }
  
      if(existingValue != null) {
        return existingValue
      }
    }

    if(prepopulateField == 'updateSelected') {
      if(uploadedGrouping["nextgeneration"]){
        return uploadedGrouping["nextgeneration"];
      }
    }

    return null;
  }

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setInputs({});
    onReject();
  };

  const processInputData = (data, valueIdentifier, newValue, inputObject) => {
    var processedGroupingValue = JSON.parse(JSON.stringify(inputObject));
    if (!processedGroupingValue[data.sku]) {
      processedGroupingValue[data.sku] = {};
    }
    if (!processedGroupingValue[data.sku][data.countryCode]) {
      processedGroupingValue[data.sku][data.countryCode] = {};
    }
    if (
      !processedGroupingValue[data.sku][data.countryCode][
        data.carrierCode ? data.carrierCode : "default"
      ]
    ) {
      processedGroupingValue[data.sku][data.countryCode][
        data.carrierCode ? data.carrierCode : "default"
      ] = {};
    }

    processedGroupingValue[data.sku][data.countryCode][
      data.carrierCode ? data.carrierCode : "default"
    ][valueIdentifier] = newValue;

    return processedGroupingValue;
  };

  const handleAccept = () => {
    setDisabled(true);
    var data = convertToArticleGroupingMappingObject(inputs, entity)
    if(data["articleGroupingRedundantList"] && data["articleGroupingRedundantList"] .length) {
        onPromptError([...new Set(data["articleGroupingRedundantList"])]);
    } else {
        onAccept(data["articleGroupingList"]);
    }
  };

  const TableCell = withStyles({
    
  })(MuiTableCell);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      transitionDuration={{
        enter: 1,
        exit: 1,
      }}
      classes={{ paper: customStyleClass.autoCompletePaper}}
    >
      <div />
      <DialogContent>
        <DialogContentText>
          <div>
            <div className="padding-10">
              <Grid container item md={12}>
                <Grid item md={12}>
                  <Typography variant="h6" color="primary">
                    {t("messages.previewGrouping")}
                  </Typography>
                  <div style={{ padding: "10px 0" }}>
                    <Typography variant="body1" className="grouping-header">
                      {t("messages.previewGroupingDescription")}
                    </Typography>
                    <Typography
                      style={{ float: "right" }}
                      variant="h7"
                      color="primary"
                    >{`${
                      entity != null ? Object.keys(entity).length : 0
                    } uploaded`}</Typography>
                  </div>
                  <Divider
                    style={{ marginTop: "16px", marginBottom: "16px" }}
                  />
                  <div>
                    <TableContainer style={{ maxHeight: 300, margin: "20px" }}>
                      <Table>
                        <TableBody>
                          {
                            <TableRow className={customStyleClass.headers}>
                              <TableCell className={customStyleClass.sticky}><b>SKU</b></TableCell>
                              <TableCell><b>Region</b></TableCell>
                              <TableCell><b>Partner</b></TableCell>
                              <TableCell><b>Article Description</b></TableCell>
                              <TableCell><b>L4L</b></TableCell>
                              <TableCell><b>Next Generation</b></TableCell>
                            </TableRow>
                          }
                          {entity != null ? (
                            entity.map((articleLikeFor, index) => 
                            {
                              return (
                                <TableRow key={index}>
                                  <TableCell className={customStyleClass.sticky} key={`skuVal-${index}`}>
                                    {" "}
                                    {articleLikeFor.sku}
                                  </TableCell>
                                  <TableCell key={`countryCode-${index}`}>
                                    {" "}
                                    {articleLikeFor.countryCode}
                                  </TableCell>
                                  <TableCell key={`carrierCode-${index}`}>
                                    {" "}
                                    {articleLikeFor?.carrierCode}
                                  </TableCell>
                                  <TableCell key={`articleDescription-${index}`}>
                                    {" "}
                                    {articleDescription?.[articleLikeFor?.sku]}
                                  </TableCell>
                                  <TableCell >
                                    <div style={{ width: "220px" }}>
                                      {
                                        inputs?.[articleLikeFor.sku]?.[
                                          articleLikeFor?.countryCode
                                        ]?.[
                                          articleLikeFor?.carrierCode
                                            ? articleLikeFor?.carrierCode
                                            : "default"
                                        ]?.["likeForLike1"]
                                      }
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    {" "}
                                    {articleLikeFor?.nextgeneration}
                                  </TableCell>
                                </TableRow>
                              )
                            }
                            )
                          ) : (
                            <TableRow></TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <Divider
                    style={{ marginTop: "16px", marginBottom: "16px" }}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "10px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  style={{
                    borderColor: "black",
                  }}
                >
                  {"Cancel"}
                </Button>
                <Button
                  disabled={!isFormValid()}
                  onClick={handleAccept}
                  size="small"
                  variant="contained"
                  color="secondary"
                  style={{
                    background: "#E3D900",
                    padding: "16px",
                  }}
                  autoFocus
                >
                  {"Publish"}
                </Button>
              </div>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ArticleNextGenerationUploadDialog;
