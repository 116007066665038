import React, { useRef } from "react";
import _ from 'lodash';
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import FilterDrawer from "../FilterDrawer";
import DataTableLoader from "../DataTableLoader";
import {withStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ConfirmationDialogSimple from "../dialogs/ConfirmationDialogSimple";
import { convertToArticleUpdateRecommendtionObject, convertToArticleRefreshRecommendtionObject } from "../../utils/CsvObjectModifier/ArticleLikeForGrouping/ArticleGroupingMapper";
import RecommendationCheckBox from "./RecommendationCheckBox";
import { parseISO, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const customStyles = makeStyles({
    table: {
        width: '100%',
    },
    countryCell: {
        border: 0,
        whiteSpace: 'nowrap',

    },
    aiRecommendedTableCell: {
        color: 'blue'
    },
    keyArrowIcon : {
        width: '15px',
        height: '15px'
    },    
    flexDisplaySpaceBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 10
    },
    descriptionColorSampleBox: {
        width: "15px", 
        height: "15px", 
        fontSize: 'initial', 
        display: 'inline-block'
    }
});

const TableCell = withStyles({
    root: {
       fontSize: '12px',
      borderBottom: "none"
    }
  })(MuiTableCell);

const styles = {
    description: {
        padding: 10,
        paddingTop: 0,
        fontSize: 15,
        fontWeight: 500,
        marginBottom: 0,
    },
    main: {
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30
    },
    mainPaper: {
        padding: 0,
        marginBottom: 12
    },
    mainWrapper: {
        paddingTop: 0
    },

}

const ArticleLikeForNextGenerationUpdatesTable = ({ itemsResponse, filterKeys, filterValues, onInputChange, onValueSubmit, onCancel, showContentLoader, articleLikeForCountryCarrier, fetchData}) => {
    const { t } = useTranslation();
    const [isFiltered, setIsFiltered] = React.useState(false);
    const classes = customStyles();
    const inputs = useRef({});
    const [checked, setChecked] = useState({});

    const [confirmationDialog, setConfirmationDialog] = useState({
        entity: null,
        open: false,
        field: null,
        action: null,
        dynamicContent: null,
        action: null
    })

    const items = itemsResponse.countryCarrierNextGenerationList ? itemsResponse.countryCarrierNextGenerationList : {};

    useEffect(() => {
        populateInput()
    }, [itemsResponse]);

    const populateInput = () => {
        var generateInputs = {};
        var checkedValue = {};

        Object.entries(items).map(([countryCode, countryValue]) => {
            generateInputs[countryCode] = {};
            checkedValue[countryCode] ={};

            Object.entries(items[countryCode]).map(([carrierCode, carrierValue]) => {
                generateInputs[countryCode][carrierCode] = {};
                checkedValue[countryCode][carrierCode] = {};

                carrierValue['articleNextGenerationOptions'].map((data) => {
                    generateInputs[countryCode][carrierCode][data.description] = {};
                    var nextGenerationData = data;
                    nextGenerationData.sku = data.sku
                    generateInputs[countryCode][carrierCode][data.description] = nextGenerationData;
                    checkedValue[countryCode][carrierCode][data.description] = false;
                })
            })
        })
        inputs.current  = generateInputs;
        setChecked({ ...checkedValue })
    }

    const onCloseDialog = () => {
        setConfirmationDialog(prevState => ({
            ...prevState,
            entity: null,
            open: false,
            field: null,
            action: null,
            dynamicContent:  null,
            onAccept: null
        }));

    }

    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        setIsFiltered(false);
        onCancel(flattenCountryCarrier[newValue].carrierCode, 1);
    };

    const handlePageChange = (event, page) => {
        fetchData(flattenCountryCarrier[activeTab].carrierCode, page);
    };

    var flattenCountryCarrier = [];
    Object.keys(articleLikeForCountryCarrier).forEach(countryCode => {
        articleLikeForCountryCarrier[countryCode].forEach(carrierCode => {
            flattenCountryCarrier.push({
                key: `${countryCode}${carrierCode}`,
                label: `${countryCode} ${carrierCode}`,
                countryCode: countryCode,
                carrierCode: carrierCode
            });
        });
    });

    return (
        <>
            <ConfirmationDialogSimple
                        entity={confirmationDialog.entity}
                        field={confirmationDialog.field}
                        open={confirmationDialog.open}
                        action={confirmationDialog.action}
                        dynamicContent={confirmationDialog.dynamicContent}
                        onReject={() => onCloseDialog()}
                        onAccept={ confirmationDialog?.onAccept}
            />
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3 style={{ paddingLeft: 30, paddingTop: 30 }}>{"Like-for-like Next Generation Update"}</h3>
            </Box>

            <div style={styles.main}>
                <Divider />
                <Divider />
                <Box className={classes.flexDisplaySpaceBetween}>
                </Box>

                <Paper style={styles.mainPaper}>
                    <Box style={styles.mainWrapper}>
                    <div style={{overflow: 'auto', maxWidth: '100'}}>
                <Table size="small"  className={classes.table}>
                    <TableBody>
                        <Tabs value={activeTab} onChange={handleTabChange}>
                            {flattenCountryCarrier.map((item, index) => {
                                return (
                                    <Tab key={index} label={item.label} />
                                )
                            })}
                        </Tabs>
                        <TableRow>
                            <TableCell colSpan={4}>
                                <FilterDrawer
                                    filterKeys={filterKeys}
                                    filterValues={filterValues}
                                    onInputChange={(filterKey, filterValue, multipleSelection) => onInputChange(filterKey, filterValue.target.value, multipleSelection)}
                                    onValueSubmit={() => {
                                        setIsFiltered(true)
                                        onValueSubmit(flattenCountryCarrier[activeTab].carrierCode)}
                                    }
                                    onCancel={() => {
                                        setIsFiltered(false)
                                        onCancel(flattenCountryCarrier[activeTab].carrierCode)}
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        {showContentLoader ? <TableRow>
                            <TableCell colSpan={4}>
                                <DataTableLoader />
                            </TableCell>
                        </TableRow> : items != null && Object.keys(items).length !== 0 ? (
                            <>
                                {
                                    Object.entries(items).map(
                                        ([countryCode, countryCodeValue]) => {

                                        return (
                                            <>
                                                {items != null && !_.isEmpty(items) &&
                                                    Object.entries(items[countryCode]).map(
                                                        ([carrierCode, carrierValue], carrierIndex) => {

                                                        return (
                                                            <>
                                                            <TableRow>
                                                                <TableCell colSpan={4}>
                                                                    <Grid container justifyContent="space-between">
                                                                        <div></div>
                                                                        <Pagination count={carrierValue['totalPageNumber']} page={carrierValue['currentPageNumber']} onChange={handlePageChange}/>
                                                                    </Grid>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                    <TableCell align={'center'}> <strong>Cover Model</strong></TableCell>
                                                                    <TableCell align={'center'}><strong>Default Like-to-Like Mapping</strong></TableCell>
                                                                    <TableCell align={'center'}><strong>Next Generation Like-to-Like Mapping</strong></TableCell>
                                                                    <TableCell></TableCell>
                                                            </TableRow>
                                                        {
                                                            
                                                            carrierValue['articleNextGenerationOptions'].map((recommendation) => {
                                                                return (
                                                                   
                                                                    <TableRow style={{height:'54px'}}>
                                                                        <TableCell style={{whiteSpace:'nowrap', width: '30%'}}>
                                                                            <strong align={'center'}>
                                                                                {recommendation.description}
                                                                            </strong>
                                                                        </TableCell>
                                                                        <TableCell style={{width: '20%'}} align={'center'}>{recommendation?.likeForLikeTagForMapping}</TableCell>
                                                                        <TableCell style={{width: '20%'}} align={'center'}>{recommendation?.nextGenerationTagForMapping}</TableCell>
                                                                        <TableCell></TableCell>
                                                                    </TableRow>
                                                                )

                                                                
                                                            })
                                                        }
                                                        <TableRow>
                                                            <TableCell colSpan={4}>
                                                                <Grid container justifyContent="center">
                                                                    <Pagination count={carrierValue['totalPageNumber']} page={carrierValue['currentPageNumber']} onChange={handlePageChange}/>
                                                                </Grid>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>

                                                        )   
                                                        }
                                                    )

                                                }
                                            
                                            </>
                                        );
                                        }
                                    )
                                }

                            </>
                            
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <p style={{ textAlign: "center" }}> {isFiltered ? t('messages.articleLikeFor.listPageEmptyFilterResult') : t('messages.articleLikeFor.nextGenerationListPageBlank')}</p>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
                    </Box>
                </Paper>



            </div>
        </>
    )

}

export default ArticleLikeForNextGenerationUpdatesTable;