import React from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Divider,
  Table,
  TableBody,
  TableRow,
  withStyles,
  TableContainer,
  Paper
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MuiTableCell from "@material-ui/core/TableCell";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import { useState, useEffect } from "react";
import { convertToArticleGroupingMappingObject } from "../../../utils/CsvObjectModifier/ArticleLikeForGrouping/ArticleGroupingMapper";
import _ from "lodash";
import "../../../styles/custom-autocomplete-dropdown.css";
import ArticleLikeForDropDown, {ArticleLikeForDropDownTableHeader} from "../../Forms/ArticleLikeFor/ArticleLikeForDropDown";
import { LIKE_FOR_SIMILAR_INPUT_FIELDS } from "../../../utils/CsvObjectModifier/CsvObjectFormat";
import makeStyles from "@material-ui/core/styles/makeStyles";

const ArticleLikeForSimilarUploadDialog = ({
  entity,
  open,
  prepopulateField,
  onReject,
  onAccept,
  tagsForMapping,
  groupingBySku,
  recommendationTagsForMapping,
  onPromptError,
  onAutoCompleteFetch,
  clearRecommendationTagsForMapping
}) => { 
  useEffect(() => {
    setArticleDescription(_.fromPairs(_.map(groupingBySku, item => [item.sku, item.description])))
    populateInput(groupingBySku, recommendationTagsForMapping);
  }, [groupingBySku, open]);

  const { t } = useTranslation();
  const [disabled, setDisabled] = React.useState(false);
  const [inputs, setInputs] = useState({});
  const [highLightedInputs, sethighLightedInputs] = useState({});
  const [likeForLikeInputError, setLikeForLikeInputError] = React.useState({});
  const [articleDescription, setArticleDescription] = React.useState({});
  const [openAutoComplete, setOpenAutoComplete] = React.useState(null);

  const handleAutoCompleteOpen = (fieldKey, sku, countryCode, carrierCode) => {
    if (!recommendationTagsForMapping[sku] || !recommendationTagsForMapping[sku][countryCode][carrierCode]){
      onAutoCompleteFetch(sku, countryCode, carrierCode);
    }
    
    setOpenAutoComplete(fieldKey)
  }

  const customStyles = makeStyles({
    sticky: {
      position: "sticky",
      left: 0,
      background: "white",
      zIndex: 2
    },
    headers: {
      position: "sticky",
      top: 0,
      background: "white",
      zIndex: 3
    },
    autoCompletePaper: { minWidth: "100%" },
  });
  const customStyleClass = customStyles();

  const filterRecommendedTagForMappingArray = (articleLikeFor) => {
    var carrierCodeValues = recommendationTagsForMapping?.[articleLikeFor?.sku]?.[articleLikeFor?.countryCode.slice(0, 2)]?.[articleLikeFor.carrierCode];
    var recommendationArray = null;

    if(carrierCodeValues != null && recommendationTagsForMapping != null) {
      recommendationArray = [];
      carrierCodeValues.map((carrierCodeValue, index) => {
        var selectionObject = {};
        selectionObject["label"] = `${carrierCodeValue["groupingTag"]} (${carrierCodeValue["quantity"]}) (${carrierCodeValue["description"]})`;
        selectionObject["iteration"] = carrierCodeValue["iteration"];
        selectionObject["description"] = carrierCodeValue["description"];
        selectionObject["launchDate"] = carrierCodeValue["launchDate"];
        selectionObject["retailPriceDifference"] = carrierCodeValue["retailPriceDifference"];
        selectionObject["refurbished"] = carrierCodeValue["quantityByStockLocation"]["refurbished"];
        selectionObject["preOwned"] = carrierCodeValue["quantityByStockLocation"]["preOwned"];
        selectionObject["new"] = carrierCodeValue["quantityByStockLocation"]["new"];
        selectionObject["likeForSimilarOption"] = carrierCodeValue["likeForSimilarOption"];
        selectionObject["likeForSimilarOptionOne"] = carrierCodeValue["likeForSimilarOptionOne"];
        selectionObject["likeForSimilarOptionTwo"] = carrierCodeValue["likeForSimilarOptionTwo"];
        selectionObject["likeForSimilarOptionThree"] = carrierCodeValue["likeForSimilarOptionThree"];
        selectionObject["value"] = carrierCodeValue["groupingTag"];
        selectionObject["group"] = 'Recommended';

        if(index === 0) {
          selectionObject["requireTableHeader"] = true;
        }

        recommendationArray.push(selectionObject);
      })

      tagsForMapping?.[articleLikeFor?.countryCode.slice(0, 2)]?.map((carrierCodeValue, index) => {
        var selectionObject = {};
        selectionObject["label"] = carrierCodeValue;
        selectionObject["value"] = carrierCodeValue;
        selectionObject["description"] = carrierCodeValue;
        selectionObject["group"] = 'All';


        recommendationArray.push(selectionObject);
      })

      return recommendationArray;
    }

    return recommendationArray;
  }

  const formatLikeForSimilarCategory = (likeForSimilarIndex , articleLikeFor, recommendationArray) => {
    const recommendationArrayObject = _.cloneDeep(recommendationArray)
    var modifiedArray = [];
    const customSortOrder = ['Existing', 'Recommended By AI', 'Recommended By Rules', 'All'];

    modifiedArray = recommendationArrayObject.filter(option => option["group"] === "All" || (option["group"] === "Recommended" &&  (option["likeForSimilarOption"] == 0 || option.likeForSimilarOption == likeForSimilarIndex)));

    modifiedArray = modifiedArray.map((option) => {
      if (option["likeForSimilarOption"] == likeForSimilarIndex) {
        option["group"] = 'Recommended By AI';
      }

      if( option["group"] === "Recommended" && option["likeForSimilarOption"] !== likeForSimilarIndex) {
        option["group"] = 'Recommended By Rules';
      }
      return option;
    });

    var existingArticleLikeforSimilarValue = inputs?.[articleLikeFor.sku]?.[
      articleLikeFor?.countryCode
    ]?.[
      articleLikeFor?.carrierCode
        ? articleLikeFor?.carrierCode
        : "default"
    ]?.[`likeForSimilarExistingValue${likeForSimilarIndex}`];

    if(existingArticleLikeforSimilarValue != null && recommendationArrayObject != null){
      var existingObject = {};
      existingObject["label"] = existingArticleLikeforSimilarValue;
      existingObject["value"] = existingArticleLikeforSimilarValue;
      existingObject["description"] = existingArticleLikeforSimilarValue;
      existingObject["group"] = 'Existing';
      existingObject["requireTableHeader"] = true;
      modifiedArray.push(existingObject);
    }

    modifiedArray = _.orderBy(
      modifiedArray,
      (option) => customSortOrder.indexOf(option["group"]),
      'asc'
    );

    return modifiedArray;
  }

  const isFormValid = () => {
    return Object.keys(likeForLikeInputError).length === 0;
  };

  const populateInput = (existingGroupingValue) => {
    var existingInputObject = {};
    var existingHighLightedInputObject = {};
    var availableGroupingOptions = [
      "likeForSimilar"
    ];

    var tagsForMappingToLikeForLike = _.fromPairs(_.map(groupingBySku, item => [item.sku, item.tagForMapping]));
    entity?.map((uploadedGrouping, index) => {
      var existingGrouping = groupingBySku.filter(
        (data) => data["sku"] === uploadedGrouping["sku"]
      )[0];

      if (existingGrouping) {
        existingInputObject = processInputData(
          uploadedGrouping,
          `${"likeForLike1"}`,
          tagsForMappingToLikeForLike[existingGrouping.sku],
          existingInputObject
        );

        availableGroupingOptions.map((option, index) => {

            var carrierArray =
              existingGrouping?.[option]?.[
                uploadedGrouping["countryCode"].slice(0, 2)
              ]?.[uploadedGrouping["carrierCode"]];

            var loopCounter = 1;
            while (loopCounter < 4) {
              var counterForRecommendation = loopCounter - 1 ;
              var likeForSimilarResultValue = determineLikeForSimilarValue(uploadedGrouping, carrierArray != null ? carrierArray[counterForRecommendation] : null, loopCounter)

              existingInputObject = processInputData(
                uploadedGrouping,
                `${option}${loopCounter}`,
                likeForSimilarResultValue["value"],
                existingInputObject
              );

              if(option === "likeForSimilar") {
                existingHighLightedInputObject =  processHighLightedInputData(
                  uploadedGrouping,
                  `${option}${'HighLighted'}${loopCounter}`,
                  existingHighLightedInputObject,
                  likeForSimilarResultValue["AiRecommended"]
                );
              }

              existingInputObject = processInputData(
                uploadedGrouping,
                `${option}${'ExistingValue'}${loopCounter}`,
                carrierArray != null ? carrierArray[counterForRecommendation] : null,
                existingInputObject
              );
              loopCounter++;
              
            }
        });
      }
    });
    setInputs(existingInputObject);
    sethighLightedInputs(existingHighLightedInputObject);
  };

  const determineLikeForSimilarValue = (uploadedGrouping, existingValue, index) => {
    var result = {};
    result["value"] = null;
    result["AiRecommended"] = false;

    if(prepopulateField == 'updateAll') {
      if(uploadedGrouping["likeForSimilar"][index]){
        result["value"] = uploadedGrouping["likeForSimilar"][index];
        result["AiRecommended"] = false;
        return result;
      }
  
      var likeForSimilarValueFromAi = _.find(recommendationTagsForMapping?.[uploadedGrouping["sku"]]?.[uploadedGrouping["countryCode"].slice(0, 2)]?.[uploadedGrouping["carrierCode"]], { "likeForSimilarOption": index })?.["groupingTag"];
      if(likeForSimilarValueFromAi){
        result["value"] = likeForSimilarValueFromAi;
        result["AiRecommended"] = true;
        return result;
      } 
  
      if(existingValue != null) {
        result["value"] = existingValue;
        result["AiRecommended"] = false;
        return result
      }
    }

    if(prepopulateField == 'updateSelected') {
      if(uploadedGrouping["likeForSimilar"][index]){
        result["value"] = uploadedGrouping["likeForSimilar"][index];
        result["AiRecommended"] = false;
        return result;
      }
    }

    return result;
  }

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setInputs({});
    clearRecommendationTagsForMapping();
    onReject();
  };

  const processInputData = (data, valueIdentifier, newValue, inputObject) => {
    var processedGroupingValue = JSON.parse(JSON.stringify(inputObject));
    if (!processedGroupingValue[data.sku]) {
      processedGroupingValue[data.sku] = {};
    }
    if (!processedGroupingValue[data.sku][data.countryCode]) {
      processedGroupingValue[data.sku][data.countryCode] = {};
    }
    if (
      !processedGroupingValue[data.sku][data.countryCode][
        data.carrierCode ? data.carrierCode : "default"
      ]
    ) {
      processedGroupingValue[data.sku][data.countryCode][
        data.carrierCode ? data.carrierCode : "default"
      ] = {};
    }

    processedGroupingValue[data.sku][data.countryCode][
      data.carrierCode ? data.carrierCode : "default"
    ][valueIdentifier] = newValue;

    return processedGroupingValue;
  };

  const processHighLightedInputData = (data, valueIdentifier, inputObject, isAiRecommended) => {

    var processedGroupingValue = JSON.parse(JSON.stringify(inputObject));
    if (!processedGroupingValue[data.sku]) {
      processedGroupingValue[data.sku] = {};
    }
    if (!processedGroupingValue[data.sku][data.countryCode]) {
      processedGroupingValue[data.sku][data.countryCode] = {};
    }
    if (
      !processedGroupingValue[data.sku][data.countryCode][
        data.carrierCode ? data.carrierCode : "default"
      ]
    ) {
      processedGroupingValue[data.sku][data.countryCode][
        data.carrierCode ? data.carrierCode : "default"
      ] = {};
    }

    processedGroupingValue[data.sku][data.countryCode][
      data.carrierCode ? data.carrierCode : "default"
    ][valueIdentifier] = isAiRecommended;

    return processedGroupingValue;
  };

  const handleAccept = () => {
    setDisabled(true);
    var data = convertToArticleGroupingMappingObject(inputs, entity)
    if(data["articleGroupingRedundantList"] && data["articleGroupingRedundantList"] .length) {
        onPromptError([...new Set(data["articleGroupingRedundantList"])]);
    } else {
        onAccept(data["articleGroupingList"]);
    }
  };

  const TableCell = withStyles({
    
  })(MuiTableCell);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      transitionDuration={{
        enter: 1,
        exit: 1,
      }}
      classes={{ paper: customStyleClass.autoCompletePaper}}
    >
      <div />
      <DialogContent onClick={() => setOpenAutoComplete(null)}>
        <DialogContentText>
          <div>
            <div className="padding-10">
              <Grid container item md={12}>
                <Grid item md={12}>
                  <Typography variant="h6" color="primary">
                    {t("messages.previewGrouping")}
                  </Typography>
                  <div style={{ padding: "10px 0" }}>
                    <Typography variant="body1" className="grouping-header">
                      {t("messages.previewGroupingDescription")}
                    </Typography>
                    <Typography
                      style={{ float: "right" }}
                      variant="h7"
                      color="primary"
                    >{`${
                      entity != null ? Object.keys(entity).length : 0
                    } uploaded`}</Typography>
                  </div>
                  <Divider
                    style={{ marginTop: "16px", marginBottom: "16px" }}
                  />
                  <div>
                    <TableContainer style={{ maxHeight: 300, margin: "20px" }}>
                      <Table>
                        <TableBody>
                          {
                            <TableRow className={customStyleClass.headers}>
                              <TableCell className={customStyleClass.sticky}><b>SKU</b></TableCell>
                              <TableCell><b>Region</b></TableCell>
                              <TableCell><b>Partner</b></TableCell>
                              <TableCell><b>Article Description</b></TableCell>
                              <TableCell><b>L4L</b></TableCell>
                              <TableCell><b>L4S option1</b></TableCell>
                              <TableCell><b>L4S option2</b></TableCell>
                              <TableCell><b>L4S option3</b></TableCell>
                            </TableRow>
                          }
                          {entity != null ? (
                            entity.map((articleLikeFor, index) => 
                            {
                              const likeForSimilarRecommendations = filterRecommendedTagForMappingArray(articleLikeFor);
                              return (
                                <TableRow key={index}>
                                  <TableCell className={customStyleClass.sticky} key={`skuVal-${index}`}>
                                    {" "}
                                    {articleLikeFor.sku}
                                  </TableCell>
                                  <TableCell key={`countryCode-${index}`}>
                                    {" "}
                                    {articleLikeFor.countryCode}
                                  </TableCell>
                                  <TableCell key={`carrierCode-${index}`}>
                                    {" "}
                                    {articleLikeFor?.carrierCode}
                                  </TableCell>
                                  <TableCell key={`articleDescription-${index}`}>
                                    {" "}
                                    {articleDescription?.[articleLikeFor?.sku]}
                                  </TableCell>
                                  <TableCell >
                                    <div style={{ width: "220px" }}>
                                      {
                                        inputs?.[articleLikeFor.sku]?.[
                                          articleLikeFor?.countryCode
                                        ]?.[
                                          articleLikeFor?.carrierCode
                                            ? articleLikeFor?.carrierCode
                                            : "default"
                                        ]?.["likeForLike1"]
                                      }
                                    </div>
                                  </TableCell>

                                  {
                                    LIKE_FOR_SIMILAR_INPUT_FIELDS.map((likeForInputField, index) => {
                                      const fieldKey = `${likeForInputField["cellName"]}-${articleLikeFor.countryCode}-${articleLikeFor.sku}-${articleLikeFor?.carrierCode? articleLikeFor?.carrierCode : "default"}-${index}`;
                                      if(likeForInputField["type"] === "likeForSimilar") {
                                        return (
                                          <TableCell>
                                            <div onClick={(e) => e.stopPropagation()}>
                                              <Autocomplete
                                                open={openAutoComplete === fieldKey}
                                                onOpen={() => handleAutoCompleteOpen(fieldKey, articleLikeFor?.sku, articleLikeFor?.countryCode, articleLikeFor?.carrierCode)}
                                                onClose={() => setOpenAutoComplete(null)}
                                                PaperComponent={({ children }) => (
                                                  <Paper style={{ width: '770px',  position: 'absolute',
                                                  top: 'unset',
                                                  bottom: '100%',
                                                  right:0,
                                                  left: 'unset',
                                                  overflowY: 'auto', }}>{children}</Paper>
                                                )}
                                                freeSolo
                                                autoSelect
                                                disableCloseOnSelect={false}
                                                options={likeForSimilarRecommendations != null ? formatLikeForSimilarCategory(likeForInputField["index"], articleLikeFor, likeForSimilarRecommendations) : []}
                                                getOptionLabel={(option) => (option["label"] ? option["label"] : option)}
                                                groupBy={(option) => option["group"]}
                                                loading={true}
                                                classes={{
                                                  option: 'customDropDownSelector'
                                                }}
                                                key={fieldKey}
                                                style={{ width: "300px" }}
                                                value={
                                                  inputs?.[articleLikeFor.sku]?.[
                                                    articleLikeFor?.countryCode
                                                  ]?.[
                                                    articleLikeFor?.carrierCode
                                                      ? articleLikeFor?.carrierCode
                                                      : "default"
                                                  ]?.[likeForInputField["inputName"]]
                                                }
                                                onChange={(e, newValue) => {

                                                  setInputs((prev) => ({
                                                    ...prev,
                                                    ...processInputData(
                                                      articleLikeFor,
                                                      likeForInputField["inputName"],
                                                      newValue && newValue.hasOwnProperty("value") ? newValue.value : newValue,
                                                      inputs
                                                    ),
                                                  }));

                                                  sethighLightedInputs((prev) => ({
                                                    ...prev,
                                                    ...processHighLightedInputData(
                                                      articleLikeFor,
                                                      likeForInputField["highLightedName"],
                                                      highLightedInputs,
                                                      newValue?.['group'] === 'Recommended By AI' ? true : false
                                                    ),
                                                  }));
                                                }}
                                                renderOption={(option) => (
                                                  <ArticleLikeForDropDown option={option}></ArticleLikeForDropDown>
                                                )}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    InputProps={{
                                                      ...params.InputProps,
                                                      style: { color: highLightedInputs?.[articleLikeFor.sku]?.[
                                                        articleLikeFor?.countryCode
                                                      ]?.[articleLikeFor?.carrierCode? articleLikeFor?.carrierCode : "default"]?.[likeForInputField["highLightedName"]] ?  'blue' : '' }
                                                    }}
                                                  />
                                                )}
                                                renderGroup={(params) =>
                                                  <ArticleLikeForDropDownTableHeader params={params}/>
                                                }
                                              />
                                            </div>
                                          </TableCell>
                                        )
                                      }
                                    })
                                  }
                                </TableRow>
                              )
                            }
                            )
                          ) : (
                            <TableRow></TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <Divider
                    style={{ marginTop: "16px", marginBottom: "16px" }}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "10px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  style={{
                    borderColor: "black",
                  }}
                >
                  {"Cancel"}
                </Button>
                <Button
                  disabled={!isFormValid()}
                  onClick={handleAccept}
                  size="small"
                  variant="contained"
                  color="secondary"
                  style={{
                    background: "#E3D900",
                    padding: "16px",
                  }}
                  autoFocus
                >
                  {"Publish"}
                </Button>
              </div>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ArticleLikeForSimilarUploadDialog;
