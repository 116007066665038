export const LOG_IN = 'logIn';
export const LOG_OUT = 'logOut';
export const THIRD_PARTY_LOG_IN = 'thirdPartyLogIn';
export const OTP_GET = 'otpGet';
export const PASSWORD_EXPIRED = 'passwordExpired';
export const ERROR = 'error';
export const USER_CLEAR_FILTER = 'userClearFilter';
export const USER_CREATE = 'userCreate';
export const USER_FILTER = 'userFilter';
export const USER_GET_DETAILS = 'userGetDetails';
export const USER_GET_LIST = 'userGetList';
export const USER_SORT = 'userSort';
export const USER_UPDATE = 'userUpdate';
export const ARTICLE_AUDIT_GET_LIST = "articleAuditGetList";
export const ARTICLE_AUDIT_FILTER = "articleAuditFilter";
export const ARTICLE_AUDIT_CLEAR_FILTER = "articleAuditClearFilter";
export const ARTICLE_AUDIT_SORT = "articleAuditSort";
export const ARTICLE_AUDIT_GET_DETAILS = "articleAuditGetDetails";
export const ARTICLE_AUDIT_RESET_DETAILS = "articleAuditResetDetails";
export const ARTICLE_AUDIT_RESET_LIST = "articleAuditResetLists";
export const ARTICLE_FILTER = 'articleFilter';
export const ARTICLE_GET_DETAILS = 'articleGetDetails';
export const ARTICLE_RESET_DETAILS = 'articleResetDetails';
export const ARTICLE_GET_SUGGESTED_RETAILS_PRICE = 'articleGetSuggestedRetailPrice';
export const ARTICLE_GET_TRADE_IN_PRICE = 'articleGetTradeInPrice';
export const ARTICLE_GET_SYSTEM_CONFIGURATION = 'articleGetSystemConfiguration';
export const ARTICLE_GET_ACCESORY_MAPPING = 'articleGetAccesoryMapping';
export const ARTICLE_GET_LIKEFOR_MAPPING = 'articleGetLikeForMapping';
export const ARTICLE_GET_LIST = 'articleGetList';
export const ARTICLE_SET_OPERATION_TYPE = 'articleSetOperationType';
export const ARTICLE_LIKEFOR_UPDATE_REQUEST_CLEAR_FILTER = 'articleLikeForRequestClearFilter';
export const ARTICLE_LIKEFOR_UPDATE_REQUEST_FILTER = 'articleLikeForRequestFilter';
export const ARTICLE_LIKEFOR_UPDATE_REQUEST_GET_LIST = 'articleLikeForRequestGetList';
export const ARTICLE_LIKEFOR_UPDATE_REQUEST_SORT = 'articleLikeForRequestSort';
export const ARTICLE_LIKEFOR_REFRESH_REQUEST_CLEAR_FILTER = 'articleLikeForRefreshClearFilter';
export const ARTICLE_LIKEFOR_REFRESH_REQUEST_FILTER = 'articleLikeForRefreshFilter';
export const ARTICLE_LIKEFOR_REFRESH_REQUEST_GET_LIST = 'articleLikeForRefreshGetList';
export const ARTICLE_LIKEFOR_REFRESH_REQUEST_SORT = 'articleLikeForRefreshSort';
export const ARTICLELIKEFOR_GET_TAGS_FOR_MAPPING_LIST = 'articleLikeForGetTagsForMappingList';
export const ARTICLELIKEFOR_GET_RECOMMENDATION_TAGS_FOR_MAPPING_LIST = 'articleLikeForGetRecommendationTagsForMappingList';
export const ARTICLELIKEFOR_GET_GROUPING_BY_SKUS = 'articleLikeForGetGroupingBySku';
export const ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_LIST = 'articleLikeForGetLikeForSimilarRecommendationUpdatesList';
export const ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_SCHEDULE = 'articleLikeForGetLikeForSimilarRecommendationUpdatesSchedule';
export const ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_LIST_FILTER = 'articleLikeForGetLikeForSimilarRecommendationUpdatesListFilter';
export const ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_LIST_CLEAR_FILTER = 'articleLikeForGetLikeForSimilarRecommendationUpdatesListClearFilter';
export const ARTICLELIKEFOR_GET_LIKEFORSIMILAR_COUNTRY_CARRIER = 'articleLikeForGetLikeForSimilarCountryCarrier';
export const ARTICLELIKEFOR_GET_LIKEFOR_NEXTGENERATION_UPDATES_LIST='articleLikeForGetLikeForNextGenerationUpdatesList';
export const ARTICLELIKEFOR_GET_LIKEFOR_NEXTGENERATION_UPDATES_LIST_CLEAR_FILTER='articleLikeForGetLikeForNextGenerationUpdatesListFilter';
export const ARTICLELIKEFOR_GET_LIKEFOR_NEXTGENERATION_UPDATES_LIST_FILTER='articleLikeForGetLikeForNextGenerationUpdatesListClearFilter';
export const ARTICLE_CLEAR_FILTER = 'articleClearFilter';
export const ARTICLE_SORT = 'userUpdate';
export const REQUEST_FAILURE = 'requestFailure';
export const REQUEST_START = 'requestStart';
export const REQUEST_SUCCESS = 'requestSuccess';
export const ARTICLE_EXCLUSION_GET_LIST = 'articleExclusionGetList';
export const ARTICLE_EXCLUSION_SORT = 'articleExclusionSort';
export const ARTICLE_EXCLUSION_FILTER = 'articleExclusionFilter';
export const ARTICLE_EXCLUSION_CLEAR_FILTER = 'articleExclusionClearFilter';
export const ARTICLE_EXCLUSION_CREATE = 'articleExclusionCreate';
export const ARTICLE_EXCLUSION_GET_OPTIONS = 'articleExclusionGetOptions';
export const SET_RESET_PASSWORD_TOKEN = 'setResetPasswordToken';
export const SHOW_ARTICLE_UPLOAD_ACCORDION = 'showArticleUploadAccordion';
export const SHOW_ACCESSORY_MAPPING_UPLOAD_DIALOG = 'showAccessoryMappingUpload';
export const SHOW_SEVERITY_UPLOAD_DIALOG = 'showSeverityUpload';
export const SHOW_ARTICLE_LIKEFOR_UPLOAD_DIALOG = 'showArticleLikeForUpload';
export const SHOW_ARTICLE_LIKEFORUPGRADE_UPLOAD_DIALOG = 'showArticleLikeForUpgradeUpload';
export const SHOW_CONFIRMATION_DIALOG = 'showConfirmationDialog';
export const SHOW_DOWNLOAD_DIALOG = 'showDownloadDialog';
export const SHOW_CONTENT_LOADER = 'showContentLoader';
export const SHOW_BACKDROP_LOADER = 'showBackDropLoader';
export const SHOW_ERROR = 'showError';
export const SHOW_SUCCESS = 'showSuccess'
export const SIMPLE_DIALOG_CLOSE = 'simpleDialogClose';
export const HIDE_ARTICLE_UPLOAD_ACCORDION = 'hideArticleUploadAccordion';
export const HIDE_ACCESSORY_MAPPING_UPLOAD_DIALOG = 'hideAccessoryMappingUploadDialog';
export const HIDE_SEVERITY_UPLOAD_DIALOG = 'hideSeverityUploadDialog';
export const HIDE_ARTICLE_LIKEFOR_UPLOAD_DIALOG = 'hideArticleLikeForUpload';
export const HIDE_ARTICLE_LIKEFORUPGRADEUPLOAD_DIALOG = 'hideArticleLikeForUpgradeUpload';
export const SHOW_ARTICLE_NEXTGENERATION_UPLOAD_DIALOG = 'showArticleNextGenerationUpload';
export const HIDE_ARTICLE_NEXTGENERATION_UPLOAD_DIALOG = 'hideArticleNextGenerationUpload';
export const HIDE_CONFIRMATION_DIALOG = 'hideConfirmationDialog';
export const HIDE_DOWNLOAD_DIALOG = 'hideDownloadDialog';
export const EXPORT_FILE_CLEAR = 'exportFileClear';
export const EXPORT_FILE_GET = 'exportFileGet';
export const REMARK_CREATE = 'remarkCreate';
export const REMARK_GET_LIST = 'getRemarkList';
export const SEARCH_GET_RESULT = 'searchGetResult';
export const SEARCH_SET_FACET_PARAM_VALUE = 'searchSetFacetParamValue';
export const SEARCH_UNSET_STATE = 'searchUnsetState';
export const SHOW_LOCALIZE_ARTICLE_UPLOAD_ACCORDION = 'showLocalizeArticleUploadAccordion';
export const HIDE_LOCALIZE_ARTICLE_UPLOAD_ACCORDION = 'hideLocalizeArticleUploadAccordion';
export const LOGIN_PAGE_ENABLED = 'loginPageEnabled';
export const ARTICLE_RESIDUAL_GET_LIST = 'articleResidualGetList';
export const ARTICLE_RESIDUAL_FILTER = 'articleResidualFilter';
export const ARTICLE_RESIDUAL_CLEAR_FILTER = 'articleResidualClearFilter';
export const ARTICLE_RESIDUAL_SORT = 'articleResidualSort';
export const ARTICLE_RESIDUAL_COUNTRY_CARRIER = 'articleResidualCountryCarrier';
export const SHOW_ARTICLE_RESIDUAL_UPLOAD_ACCORDION = 'showArticleResidualUploadAccordion';
export const HIDE_ARTICLE_RESIDUAL_UPLOAD_ACCORDION = 'hideArticleResidualUploadAccordion';
export const USER_MENU_ENABLED = 'userMenuEnabled';