import React, { Component } from "react";
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    clearArticleFilters,
    getArticleList, sortArticles,
    batchUploadrticleImages,
    updateFiltersForArticleList
} from '../../actions/articleAction';
import DataTable from "../../components/DataTable";
import PageTemplate from "../../components/PageTemplate";
import ArticleImagesUpload from '../../pages/ArticleImage/ArticleImagesUpload ';
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
    parseObjectToString,
    validateAuthority
} from "../../utils/utils";
import { showError } from "../../actions/commonActions";
import { AUTHORITY_ADMIN } from "../../constant";
import { Box } from "@material-ui/core";
import _ from 'lodash';
import { Link } from "react-router-dom";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { NoteAdd } from '@material-ui/icons';

class ArticleGlobalList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openModel: false,
            requireMultiLocaliseDialog: false,
            templateType: '',
            skuCountryCodes: [],
        }
    }
    componentDidMount() {
        this.props.getArticleList('orderBy=createdAt&sort=desc');
        this.props.clearArticleFilters();
        this.props.sortArticles('createdAt', 'desc');
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.common.refresh !== prevProps.common.refresh && this.props.common.refresh
        ) {
            this.props.getArticleList('orderBy=createdAt&sort=desc');
        }
    }

    onPromptError = (data) => {
        this.props.showError({ response: { data: { translationKey: `${data}` } } });
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        console.log("filterKey "+ filterKey);
        console.log("filterValue "+ filterValue);

        if (multipleSelection) {
            let filterState = this.props.articles.articleFilters[filterKey];
            filterState.includes(filterValue) ? filterState = filterState.filter(item => item !== filterValue) : filterState.push(filterValue);
            filterValue = filterState;
        }
        this.props.updateFiltersForArticleList(filterKey, filterValue)
    }

    handleFilterReset() {
        this.props.clearArticleFilters();
        this.props.getArticleList(this.props.articles.sorting);
    }

    handleSorting(orderBy, sort) {
        this.props.sortArticles(orderBy, sort)
        this.props.getArticleList(parseObjectToString(this.props.articles.articleFilters, { orderBy, sort }))
    }

    renderUsers(articles, showContentLoader) {
        const fields = [
            {
                name: 'sku',
            },
            {
                name: 'manufacturer.code'
            },
            {
                name: 'type.code',
                label: 'articleTypeCode'
            },
            {
                name: 'description',
                label: "articleDescription"
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            },
            {
                name: 'updatedAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            },
            {
                name: 'uploader.name'
            }
        ];

        const filterKeys = [
            {
                name: 'sku',
                type: 'textField'
            },
            {
                name: 'manufacturer.code',
                type: 'textField'
            },
            {
                name: 'type.code',
                type: 'checkBoxes',
                options: ['handset', 'notebook', 'tablet', 'watch', 'headphones', 'poweradapter', 'projector', 'adapter', 'battery', 'cable', 'consumable', 'golfball', 'wearable', 'smarttv', 'gameconsole', 'parts', 'modem']
            },
            {
                name: 'description',
                type: 'textField'
            },
            {
                name: 'uploader.name',
                type: 'textField'
            },
            {
                name: 'tagForMapping',
                type: 'textField',
                label: 'article.tagForMapping.keyword'
            },
            {
                name: 'createdAt',
                type: 'dateRangePicker'
            },
            {
                name: 'updatedAt',
                type: 'dateRangePicker'
            }
        ];

        return (
            <DataTable fields={fields}
                items={articles}
                entity="articles"
                filterKeys={filterKeys}
                filterValues={this.props.articles.articleFilters}
                onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                onValueSubmit={() => this.props.getArticleList(parseObjectToString(this.props.articles.articleFilters))}
                sorting={this.props.articles.sorting}
                onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                sortingAvailable={true}
                onCancel={() => this.handleFilterReset()}
                showContentLoader={showContentLoader}
                enableFilter={true}
                disableCell={false}
            />
        )
    }

    openModel = () => {
        this.setState({ openModel: true })
    }
    handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
        return;
        this.setState({ openModel: false })
    }
    handleImageUpload = (data) => {
        this.props.batchUploadrticleImages(data)
    }

    render() {

        const MenuBar = () => {
            const { t } = useTranslation();
            return (
                <div style={{
                    paddingTop: 30,
                    paddingLeft: 30,
                    paddingRight: 30,
                    display: "flex",
                    justifyContent: "flex-end"
                }} >
                    {
                        validateAuthority(AUTHORITY_ADMIN,this.props.authentication.loggedInUser.authorities) &&  
                        <Link to={`/articles/createGlobal`} seleniumselector={`/articles/createGlobal`}>
                            <Button size="small" variant="contained" color="secondary" style={{
                           background: "#E3D900",
                           padding: "16px"
                        }} className="margin-left-10">
                                {t('messages.globalArticleCreate')}
                                <NoteAdd  style={{
                           marginLeft: "8px"
                        }} />
                            </Button>
                        </Link>
                    }
                    {
                        <Link to={`/articles/createLocalized`} seleniumselector={`/articles/createLocalized`}>
                            <Button size="small" variant="contained" color="secondary" style={{
                           background: "#E3D900",
                           padding: "16px",
                           marginRight: '10px'
                        }} className="margin-left-10">
                                {t('messages.articleLocalise')}
                                <InsertDriveFileIcon  style={{
                           marginLeft: "8px"
                        }} />
                            </Button>
                        </Link>
                    }
                     {
                        validateAuthority(AUTHORITY_ADMIN,this.props.authentication.loggedInUser.authorities) &&  
                        <Button size="small" variant="contained" color="secondary" style={{
                            background: "#E3D900",
                            padding: "16px"
                        }} onClick={this.openModel}>
                            {t('messages.uploadImages')}
                        </Button>
                    }
                </div>
            )
        }

        return (
            <>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3 style={{ paddingLeft: 30, paddingTop: 30 }}>{"Article List"}</h3>
                    <MenuBar></MenuBar>
                </Box>

                <Dialog
                    minWidth={120}
                    open={this.state.openModel}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    BackdropProps={{ onClick: null }}
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <ArticleImagesUpload onUploadImage={this.handleImageUpload} onCloseModel={this.handleClose} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                <PageTemplate uploadAction={"upload"}>
                    {this.renderUsers(this.props.articles.articles, this.props.common.showContentLoader)}
                </PageTemplate>
            </>

        )
    }

}

const mapStateToProps = state => ({
    articles: state.articles,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    getArticleList: getArticleList,
    updateFiltersForArticleList: updateFiltersForArticleList,
    clearArticleFilters: clearArticleFilters,
    batchUploadrticleImages: batchUploadrticleImages,
    sortArticles: sortArticles,
    showError
})(ArticleGlobalList);