import React, { Component, useState } from "react";
import { connect } from 'react-redux';
import { showArticleLikeForUploadDialog, hideArticleLikeForUploadDialog, 
    showArticleLikeUpgradeForUploadDialog, hideArticleLikeForUpgradeUploadDialog,
    batchCreateArticleLikeForMapping, getArticleLikeForTagsForMapping,
    getArticleGroupingBySkus, getAutomatedTagsForMapping, clearAutomatedTagsForMapping,
    showArticleNextGenerationUploadDialog, hideArticleNextGenerationUploadDialog, } from "../../actions/articleLikeForAction";
import {
    validateAcceptedFields,
    validateRequiredFields,
    validateAuthority
} from "../../utils/utils";
import _ from "lodash";
import { showError } from "../../actions/commonActions";
import ArticleLikeForSimilarUploadDialog from "../../components/dialogs/ArticleLikeFor/ArticleLikeForUploadDialog";
import ArticleLikeForUpgradeUploadDialog from "../../components/dialogs/ArticleLikeFor/ArticleLikeForUpgradeUploadDialog";
import ArticleNextGenerationUploadDialog from "../../components/dialogs/ArticleLikeFor/ArticleNextGenerationUploadDialog";
import { Box } from "@material-ui/core";
import FilesUpload from "../../components/FilesUpload";
import { fileUploadAcceptedFields, fileUploadRequiredFields, fileUploadRequiredHeaders } from "../../utils/fields";
import { convertToArticleGroupingMappingObject,convertToArticleGroupingRecommendationRequest } from "../../utils/CsvObjectModifier/ArticleLikeForGrouping/ArticleGroupingMapper";
import { LIKEFORSIMILAR_MAPPING_CSV_OBJECT, LIKEFORUPGRADE_MAPPING_CSV_OBJECT, NEXTGENERATION_MAPPING_CSV_OBJECT } from "../../utils/CsvObjectModifier/CsvObjectFormat";
import ProgressBackdrop from "../ProgressBackdrop";
import { AUTHORITY_ADMIN } from "../../constant";
import Grid from "@material-ui/core/Grid";

class ArticleLikeForMapping extends Component {

    constructor(props) {
        super(props)
    }
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }
    onPromptError = (data) => {
        this.props.showError({ response: { data: { translationKey: `${data}` } } });
    }

    onAutoCompleteFetch = (sku, countryCode, carrierCode) => {
        let payload = [
            {
                "sku": sku,
                "countryCode": countryCode,
                "carrierCode": carrierCode
            }
        ]
        this.props.getAutomatedTagsForMapping(convertToArticleGroupingRecommendationRequest(payload))
    }

    clearRecommendationTagsForMapping = () => {
        this.props.clearAutomatedTagsForMapping()
    }

    onUpload = (data, prepopulateField, type) => {
        var validatedRecords = this.validateData(data, type);

        if (validatedRecords.lineNumberWithErrors.length === 0 && validatedRecords.recordLimitExceeded == false && validatedRecords.redundantAccessory.length === 0 && validatedRecords.accessoryWithCountrySpecificAndCarrierSpecific.length === 0 && validatedRecords.emptyRecord == false && validatedRecords.validHeader == true) {
            var skuList = [];
            var uploadedCountryCodes =[...new Set(data.map(obj => obj.countryCode.slice(0, 2)))];

            data?.map(record => {
                if (skuList.includes(record.sku) === false) skuList.push(record.sku);
            })

            this.props.getArticleGroupingBySkus(skuList);
            this.props.getArticleLikeForTagsForMapping(uploadedCountryCodes);
            switch(type) {
                case 'likeForSimilar':
                    this.props.showArticleLikeForUploadDialog(data, prepopulateField);
                    break;
                case 'likeForUpgrade':
                    this.props.showArticleLikeUpgradeForUploadDialog(data, prepopulateField);
                    break;
                default:
                    this.props.showArticleNextGenerationUploadDialog(data, prepopulateField);
                    break;
            }

        } else {
            
            if (validatedRecords.emptyRecord == true) {
                this.props.showError({ response: { data: { translationKey: `0 record uploaded. Please fix and try again.` } } });
            } 
            if (validatedRecords.validHeader == false) {
                this.props.showError({ response: { data: { translationKey: `Invalid CSV header. Please fix and try again.` } } });
            } 

            if (validatedRecords.recordLimitExceeded == true) {
                this.props.showError({ response: { data: { translationKey: `Exceeded 500 limit of records with number of ${data.length} uploaded records. Please fix and try again.` } } });
            } 
            
            if (validatedRecords.lineNumberWithErrors.length > 0){
                this.props.showError({ response: { data: { translationKey: `Error in line ${validatedRecords.lineNumberWithErrors}. Please fix and try again.` } } });
            }

            if(validatedRecords.redundantAccessory.length > 0) {
                this.props.showError({ response: { data: { translationKey: `Found record with duplicated sku, countryCode and carrierCode for sku ${validatedRecords.redundantAccessory}. Please fix and try again` } } });
            }

            if(validatedRecords.accessoryWithCountrySpecificAndCarrierSpecific.length > 0) {
                this.props.showError({ response: { data: { translationKey: `Found sku with both country specific and carrier specific record for sku ${[... new Set(validatedRecords.accessoryWithCountrySpecificAndCarrierSpecific)]}. Please fix and try again` } } });
            }
        }
    }

    validateData = (data, type) => {
        let validatedRecords = {
            valid: true,
            validHeader: true,
            lineNumberWithErrors: [],
            recordLimitExceeded: false,
            redundantAccessory: [],
            accessoryWithCountrySpecificAndCarrierSpecific: [],
            emptyRecord: false
        };
        
        var currentObjectHeader = Object.keys(data[0]);
        const requiredHeaders = (function () {
            switch (type) {
              case "likeForSimilar":
                return fileUploadRequiredHeaders.articleLikeForSimilar;
              case "likeForUpgrade":
                return fileUploadRequiredHeaders.articleLikeForUpgrade;
              default:
                return fileUploadRequiredHeaders.nextGeneration;
            }
          })();
        validatedRecords.validHeader = _.isEqual(Object.values(requiredHeaders), currentObjectHeader);

        if(!validatedRecords.validHeader) {
            return validatedRecords;
        }

        let skuWithCarrierCode =[...new Set( data.filter((obj) => obj.carrierCode).map(obj => obj.sku+"-"+ obj.countryCode))];

        data.reduce((count, accessoryObject) => {
            let key = `${accessoryObject.sku}_${accessoryObject.countryCode.slice(0, 2).toLowerCase()}_${accessoryObject.carrierCode.toLowerCase()}`;
            if (!count[key]) {
              count[key] = [accessoryObject];
            } else {
              validatedRecords.redundantAccessory.push(accessoryObject.sku);
              count[key].push(accessoryObject);
            }
            return count;
        }, {});

        data.forEach((datum, index) => {
            validatedRecords.valid = validateAcceptedFields(fileUploadAcceptedFields['articleLikeFor'], Object.keys(datum)) &&
                validateRequiredFields(fileUploadRequiredFields['articleLikeFor'], datum);
            if (validatedRecords.valid == false) {
                validatedRecords.lineNumberWithErrors.push(index + 3)
            }

            if(datum.carrierCode === '' && skuWithCarrierCode.includes(datum.sku+"-"+datum.countryCode)){
                validatedRecords.accessoryWithCountrySpecificAndCarrierSpecific.push(datum.sku);
            }
        });

        if(data.length <= 0 ) {
            validatedRecords.emptyRecord = true;
        }

        if (data.length > 500) {
            validatedRecords.recordLimitExceeded = true;
        }

        return validatedRecords;

    }

    uploadArticleLikeForMappings  = (data) => {
        this.props.hideArticleLikeForUploadDialog();
        this.props.hideArticleLikeForUpgradeUploadDialog();
        this.props.hideArticleNextGenerationUploadDialog();
        // data = convertToArticleGroupingMappingObject(data);
        this.props.batchCreateArticleLikeForMapping(data);
    }

    handleServerSideArticleCSVTemplateDownload = (data, type) => {
        var isAdmin  = validateAuthority(AUTHORITY_ADMIN,this.props.authentication.loggedInUser.authorities);
        const link = document.createElement('a');
        if(!isAdmin) {
            var countryCode = this.props.authentication.loggedInUser.countryCode
            link.href = `${process.env.PUBLIC_URL}/assets/resources/template_${type}_mapping_${countryCode}.csv`;
            link.download = `template${type}_mapping.csv`;

            link.click();
        } else {
            data.map((countryCode) => {
                link.href = `${process.env.PUBLIC_URL}/assets/resources/template_${type}_mapping_${countryCode}.csv`;
                link.download = `template_${type}_mapping_${countryCode}.csv`;

                link.click();
            })
        }

    }

    render() {
        const defaultFields = {
            countryCode: this.props.authentication.loggedInUser.countryCode
        }
        const customeSliceCsvRecord = 1;
        const { t } = this.props;

        return (
            <>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3 style={{ paddingLeft: 30, paddingTop: 30 }}>{"Like for Like, Similar, Upgrade Mapping, Next Generation Mapping"}</h3>
                </Box>
                <Grid container style={{marginTop: "8px"}}>
                    <Grid item md={4} style={{padding: "8px"}}>
                        <FilesUpload defaultFields={defaultFields}
                            templateFile={`template_likefor_mapping.csv`}
                            onUpload={(data, prepopulateField, type) => this.onUpload(data, prepopulateField, 'likeForSimilar')}
                            changeHeader={LIKEFORSIMILAR_MAPPING_CSV_OBJECT}
                            sliceCsv={customeSliceCsvRecord}
                            isAdmin={validateAuthority(AUTHORITY_ADMIN,this.props.authentication.loggedInUser.authorities)}
                            handleServerSideArticleCSVTemplateDownload={(data, type) => this.handleServerSideArticleCSVTemplateDownload(data, 'likeforSimilar')}
                            titleName={'messages.createLikeForMapping'}
                            processFileType = "articleLikeFor"
                        />
                    </Grid>
                    <Grid item md={4} style={{padding: "8px"}}>
                        <FilesUpload defaultFields={defaultFields}
                            templateFile={`template_likefor_mapping.csv`}
                            onUpload={(data, prepopulateField, type) => this.onUpload(data, prepopulateField, 'likeForUpgrade')}
                            changeHeader={LIKEFORUPGRADE_MAPPING_CSV_OBJECT}
                            sliceCsv={customeSliceCsvRecord}
                            isAdmin={validateAuthority(AUTHORITY_ADMIN,this.props.authentication.loggedInUser.authorities)}
                            handleServerSideArticleCSVTemplateDownload={(data, type) => this.handleServerSideArticleCSVTemplateDownload(data, 'likeforUpgrade')}
                            titleName={'messages.createLikeForUpgradeMapping'}
                            processFileType = "articleLikeFor"
                        />
                    </Grid>
                    <Grid item md={4} style={{padding: "8px"}}>
                        <FilesUpload defaultFields={defaultFields}
                            templateFile={`template_likefor_mapping.csv`}
                            onUpload={(data, prepopulateField, type) => this.onUpload(data, prepopulateField, 'nextGeneration')}
                            changeHeader={NEXTGENERATION_MAPPING_CSV_OBJECT}
                            sliceCsv={customeSliceCsvRecord}
                            isAdmin={validateAuthority(AUTHORITY_ADMIN,this.props.authentication.loggedInUser.authorities)}
                            handleServerSideArticleCSVTemplateDownload={(data, type) => this.handleServerSideArticleCSVTemplateDownload(data, 'nextgeneration')}
                            titleName={'messages.createNextGenerationMapping'}
                            processFileType = "articleLikeFor"
                        />
                    </Grid>
                </Grid>

                 <ArticleLikeForSimilarUploadDialog entity={this.props.articleLikeFors.articleLikeForUploadDialog.entity}
                        open={this.props.articleLikeFors.articleLikeForUploadDialog.open}
                        prepopulateField={this.props.articleLikeFors.articleLikeForUploadDialog.prepopulateField}
                        onReject={() => this.props.hideArticleLikeForUploadDialog()}
                        onAccept={(data) => this.uploadArticleLikeForMappings(data)}
                        tagsForMapping={this.props.articleLikeFors.tagsForMapping}
                        recommendationTagsForMapping={this.props.articleLikeFors.recommendationTagsForMapping}
                        groupingBySku={this.props.articleLikeFors.groupingBySku}
                        onPromptError={(data) => this.onPromptError(data)}
                        onAutoCompleteFetch={(sku, countryCode, carrierCode) => this.onAutoCompleteFetch(sku, countryCode, carrierCode)}
                        clearRecommendationTagsForMapping={() => this.clearRecommendationTagsForMapping()}
                />

                <ArticleLikeForUpgradeUploadDialog entity={this.props.articleLikeFors.articleLikeForUpgradeUploadDialog.entity}
                        open={this.props.articleLikeFors.articleLikeForUpgradeUploadDialog.open}
                        prepopulateField={this.props.articleLikeFors.articleLikeForUpgradeUploadDialog.prepopulateField}
                        onReject={() => this.props.hideArticleLikeForUpgradeUploadDialog()}
                        onAccept={(data) => this.uploadArticleLikeForMappings(data)}
                        tagsForMapping={this.props.articleLikeFors.tagsForMapping}
                        groupingBySku={this.props.articleLikeFors.groupingBySku}
                        onPromptError={(data) => this.onPromptError(data)}
                />

                <ArticleNextGenerationUploadDialog entity={this.props.articleLikeFors.articleNextGenerationUploadDialog.entity}
                        open={this.props.articleLikeFors.articleNextGenerationUploadDialog.open}
                        prepopulateField={this.props.articleLikeFors.articleNextGenerationUploadDialog.prepopulateField}
                        onReject={() => this.props.hideArticleNextGenerationUploadDialog()}
                        onAccept={(data) => this.uploadArticleLikeForMappings(data)}
                        tagsForMapping={this.props.articleLikeFors.tagsForMapping}
                        groupingBySku={this.props.articleLikeFors.groupingBySku}
                        onPromptError={(data) => this.onPromptError(data)}
                />

                <ProgressBackdrop open={this.props.common.showBackDropLoader}/>
            </>

        )
    }

}

const mapStateToProps = state => ({
    articleLikeFors: state.articleLikeFors,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    batchCreateArticleLikeForMapping: batchCreateArticleLikeForMapping,
    showArticleLikeForUploadDialog: showArticleLikeForUploadDialog,
    hideArticleLikeForUploadDialog: hideArticleLikeForUploadDialog,
    showArticleLikeUpgradeForUploadDialog: showArticleLikeUpgradeForUploadDialog,
    hideArticleLikeForUpgradeUploadDialog: hideArticleLikeForUpgradeUploadDialog,
    showArticleNextGenerationUploadDialog: showArticleNextGenerationUploadDialog,
    hideArticleNextGenerationUploadDialog: hideArticleNextGenerationUploadDialog,
    getArticleLikeForTagsForMapping: getArticleLikeForTagsForMapping,
    getAutomatedTagsForMapping: getAutomatedTagsForMapping,
    getArticleGroupingBySkus: getArticleGroupingBySkus,
    clearAutomatedTagsForMapping: clearAutomatedTagsForMapping,
    showError
})(ArticleLikeForMapping);