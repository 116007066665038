import React, {Component} from "react";
import { connect } from 'react-redux';
import { clearArticleLikeForNextGenerationUpdatesFilter,
getArticleLikeForNextGenerationUpdates,
updateFiltersForArticleLikeForNextGenerationUpdatesFilter,
getArticleLikeForSimilarCountryCarrier } from "../../actions/articleLikeForAction";
import ArticleLikeForNextGenerationUpdatesTable from "../../components/Article/ArticleLikeForNextGenerationUpdatesTable";
import { parseObjectToString } from "../../utils/utils";
import { USER_COUNTRY_CODES } from "../../constant";
import { showConfirmationDialog, showError } from "../../actions/commonActions";

class ArticleLikeForNextGenerationUpdates extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getArticleLikeForSimilarCountryCarrier('articleNextGeneration.sync.countryCarrier').then(() => {
            const entries = Object.entries(this.props.articleLikeFors.articleLikeForCountryCarrier);
            
            const userCountryCodeEntry = entries.find(([key]) => key === this.props.authentication.loggedInUser.countryCode);
            const otherEntries = entries.filter(([key]) => key !== this.props.authentication.loggedInUser.countryCode).sort((a, b) => a[0].localeCompare(b[0]));;
            
            const sortedEntries = userCountryCodeEntry ? [userCountryCodeEntry, ...otherEntries] : otherEntries;
            
            const sortedObject = Object.fromEntries(sortedEntries);
            this.props.articleLikeFors.articleLikeForCountryCarrier = sortedObject;

            let carrierCode = this.retrieveFirstCarrier(this.props.articleLikeFors.articleLikeForCountryCarrier);

            this.props.getArticleLikeForNextGenerationUpdates(`orderBy=createdAt&sort=desc&size=100&page=1&filterFields[]=carrierCode&filterValues[]=${carrierCode}`)
        })
        this.props.clearArticleLikeForNextGenerationUpdatesFilter();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    retrieveFirstCarrier(countryCarrier) {
        let result = null;
        for (const country of Object.keys(countryCarrier)) {
            if (countryCarrier[country].length > 0){
                result = countryCarrier[country][0];
                break;
            }
        }
        return result;
    }

    fetchArticleLikeForNextGenerationUpdates(carrierCode, pageNumber) {
        this.props.getArticleLikeForNextGenerationUpdates(`orderBy=createdAt&sort=desc&size=100&page=${pageNumber}&filterFields[]=carrierCode&filterValues[]=${carrierCode}&${parseObjectToString(this.props.articleLikeFors.likeForNextGenerationUpdatesListFilters)}${this.props.articleLikeFors.likeForNextGenerationUpdatesListFilters.description != '' ? '&filterFieldsForPartialSearch[]=description' : ''}`)
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        console.log("filterKey "+ filterKey);
        console.log("filterValue "+ filterValue);

        if (multipleSelection) {
            let filterState = this.props.articleLikeFors.likeForNextGenerationUpdatesListFilters[filterKey];
            filterState.includes(filterValue) ? filterState = filterState.filter(item => item !== filterValue) : filterState.push(filterValue);
            filterValue = filterState;
        }
        this.props.updateFiltersForArticleLikeForNextGenerationUpdatesFilter(filterKey, filterValue)
    }

    handleFilterReset(carrierCode) {
        this.props.clearArticleLikeForNextGenerationUpdatesFilter();
        this.props.getArticleLikeForNextGenerationUpdates(`orderBy=createdAt&sort=desc&size=100&page=1&filterFields[]=carrierCode&filterValues[]=${carrierCode}`)
    }

    renderLikeForNextGenerationUpdateLists(likeForSimilarRecommendationUpdatesList, showContentLoader) {
        const filterKeys = [
            {
                name: 'description',
                type: 'textField'
            }
        ];

        return(
            <ArticleLikeForNextGenerationUpdatesTable
                itemsResponse={likeForSimilarRecommendationUpdatesList}
                filterKeys={filterKeys}
                filterValues={this.props.articleLikeFors.likeForNextGenerationUpdatesListFilters}
                onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                onValueSubmit={(carrierCode) => {
                this.props.getArticleLikeForNextGenerationUpdates(`orderBy=createdAt&sort=desc&size=100&page=1&filterFields[]=carrierCode&filterValues[]=${carrierCode}&${parseObjectToString(this.props.articleLikeFors.likeForNextGenerationUpdatesListFilters)}${this.props.articleLikeFors.likeForNextGenerationUpdatesListFilters.description != '' ? '&filterFieldsForPartialSearch[]=description' : ''}`)}
                }
                onCancel={(carrierCode) => this.handleFilterReset(carrierCode)}
                showContentLoader={showContentLoader}
                articleLikeForCountryCarrier={this.props.articleLikeFors.articleLikeForCountryCarrier}
                fetchData={(carrierCode, pageNumber) => this.fetchArticleLikeForNextGenerationUpdates(carrierCode, pageNumber)}
            />
        )
    }

    render() {

        return(
            <>
                {this.renderLikeForNextGenerationUpdateLists(this.props.articleLikeFors.likeForNextGenerationUpdatesList, this.props.common.showContentLoader)}
            </>
        )
    }

}

const mapStateToProps = state => ({
    articleLikeFors: state.articleLikeFors,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    getArticleLikeForNextGenerationUpdates: getArticleLikeForNextGenerationUpdates,
    updateFiltersForArticleLikeForNextGenerationUpdatesFilter: updateFiltersForArticleLikeForNextGenerationUpdatesFilter,
    clearArticleLikeForNextGenerationUpdatesFilter: clearArticleLikeForNextGenerationUpdatesFilter,
    getArticleLikeForSimilarCountryCarrier: getArticleLikeForSimilarCountryCarrier,
    showConfirmationDialog,
    showError
})(ArticleLikeForNextGenerationUpdates);